<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-text-field :error-messages="nameErrors" :label="$t('name')" @blur="$v.item.name.$touch()"
            @input="$v.item.name.$touch()" required v-model="item.name" />
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <v-text-field :error-messages="domainErrors" :label="$t('domain')" @blur="$v.item.domain.$touch()"
            @input="$v.item.domain.$touch()" v-model="item.domain" />
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <v-text-field :error-messages="mailFromMailErrors" :label="$t('mailFromMail')"
            @blur="$v.item.mailFromMail.$touch()" @input="$v.item.mailFromMail.$touch()"
            v-model="item.mailFromMail" />
          <v-text-field :label="$t('mailFromName')" v-model="item.mailFromName" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'TenantForm',
  mixins: [validationMixin],
  props: {
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {
      }
    },
    initialValues: {
      type: Object,
      default: () => {
      }
    },
  },
  mounted() {
  },
  data() {
    return {
      name: null,
      domain: null,
      mailFromMail: null,
      mailFromName: null
    };
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    nameErrors() {
      const errors = [];

      if (!this.$v.item.name.$dirty) {
        return errors;
      }

      has(this.violations, 'name') && errors.push(this.violations.name);

      !this.$v.item.name.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    domainErrors() {
      const errors = [];

      if (!this.$v.item.domain.$dirty) {
        return errors;
      }

      has(this.violations, 'domain') && errors.push(this.violations.domain);

      return errors;
    },
    mailFromMailErrors() {
      const errors = [];

      if (!this.$v.item.mailFromMail.$dirty) {
        return errors;
      }

      !this.$v.item.mailFromMail.email && errors.push(this.$t("Invalid email address."));

      return errors;
    },
    violations() {
      return this.errors || {};
    },
  },
  methods: {},
  validations: {
    item: {
      name: {
        required,
      },
      domain: {
        required,
      },
      mailFromMail: {
        email,
      }
    }
  }
};
</script>
